export enum Routes {
    HomeScreen = "home",
    FeedScreen = "feed",
    AcademyScreen = "academy",
    LoginScreen = "login",
    RegisterScreen = "register",
    ServerErrorScreen = "500error",
    NotFoundScreen = "404error",
    ProfileSettings = "settings",
    Profile = "profile",
    NewTrip = "newtrip",
    EditTrip = "edittrip",
}

export enum subRoutes {
    fishDetails = "fish",
}
