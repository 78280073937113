import { FunctionComponent } from "react";

interface StaticImageWithOverlayProps {
    imageUrl: string,
    children: React.ReactNode
}

const StaticImageWithOverlay: FunctionComponent<StaticImageWithOverlayProps> = ({ imageUrl, children }) => {

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            width: "100%",
            minHeight: "33vh",
            borderRadius: "8px",
        } as React.CSSProperties,

        infoContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "29px 24px 24px 24px",
            color: "white",
            background: "linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.8))",
            borderRadius: "0 0 8px 8px",
        } as React.CSSProperties,
    };

    return (
        <div className="centeredBackgroundImage" style={{ ...styles.container, backgroundImage: `url(${imageUrl})` }}>
            <div style={styles.infoContainer}>
                {children}
            </div>
        </div>
    );
}

export default StaticImageWithOverlay;