import { Place } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import ReactMapGL, { Marker, StaticMap as StaticMapGl } from "react-map-gl";

interface StaticMapProps {
    lat: string | number,
    lon: string | number
}

const StaticMap: React.FunctionComponent<StaticMapProps> = ({ lat, lon }) => {
    // const [lng, setLng] = useState(10.89389);
    // const [lat, setLat] = useState(56.48245);
    const [zoom, setZoom] = useState(8);

    const styles = {
        mapContainer: {
            width: "100vw",
            height: "240px",
            zIndex: -1,
        } as React.CSSProperties
    }

    return (
        <div style={styles.mapContainer}>
            <StaticMapGl
                mapboxApiAccessToken={"pk.eyJ1IjoiZGVlYm5keCIsImEiOiJja3c0bWh6NWYwNXN5Mm9yaHA1MjZiNTAzIn0.iM8Hq_r7-UDi1TGBNGg2vA"}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                latitude={+lat}
                longitude={+lon}
                zoom={zoom}
                width={"100vw"}
                height={"100%"}
                asyncRender={true}
            >
                <Marker
                    longitude={+lon}
                    latitude={+lat}
                >
                    <Place
                        color={"secondary"}
                        fontSize={"large"}
                        style={{ transform: "translate(-50%, -100%)" }}
                    />
                </Marker>
            </StaticMapGl>
        </div>
    );
};

export default StaticMap;