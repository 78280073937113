import { Close, Email, Lock } from "@mui/icons-material";
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { useStore } from "../../stores/store";

interface NewCatchModalProps {
    open: boolean;
    handleClose?: () => {} | void;
}

const NewCatchModal: FunctionComponent<NewCatchModalProps> = ({ open, handleClose }) => {

    const { tripStore } = useStore();
    const { addNewCatch, fishSelect } = tripStore;

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "92vw",
        maxWidth: 540,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        outline: 0,
        borderRadius: 2,
        color: "black",
    };

    const submitForm = (values: any) => {
        addNewCatch(values);
        handleClose!();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                <div style={{ marginBottom: 40, display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                    <p style={{ textAlign: "center", fontSize: 24 }}>Ny Fangst</p>
                    <Close onClick={handleClose} style={{ cursor: "pointer" }} />
                </div>

                <Formik
                    initialValues={{
                        fishId: "",
                        weight: "",
                        length: "",
                        error: null,
                    }}
                    onSubmit={(values) => submitForm(values)}
                // onSubmit={(values, { setErrors }) => accountStore.login(values).catch(error => setErrors({ error: "Ugyldig Email eller kodeord" }))}
                >

                    {({ handleSubmit, handleChange, isSubmitting, errors }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off" style={{ zIndex: 1 }}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel id="demo-simple-select-label">Fiskeart</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    displayEmpty
                                    name="fishId"
                                    label="Fiskeart"
                                    onChange={handleChange}
                                >
                                    {
                                        fishSelect?.map(x => {
                                            return (
                                                <MenuItem key={x.id} value={x.id}> {x.fishName} </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <div style={{ display: "flex", flexDirection: "row", columnGap: 24 }}>
                                <TextField
                                    style={{ margin: "16px 0" }}
                                    name="weight"
                                    placeholder="Vægt"
                                    label="Vægt"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"> kg </InputAdornment>,
                                    }}
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="error"
                                    render={() => <p style={{ marginBottom: 16, color: "#FB2626" }}>{errors.error}</p>}
                                />

                                <TextField
                                    style={{ margin: "16px 0" }}
                                    name="length"
                                    placeholder="længde"
                                    label="Længde"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"> cm </InputAdornment>,
                                    }}
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="error"
                                    render={() => <p style={{ marginBottom: 16, color: "#FB2626" }}>{errors.error}</p>}
                                />
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Button
                                    type="reset"
                                    variant="text"
                                    size={"large"}
                                    style={{ marginTop: 24 }}
                                    onClick={handleClose}
                                >
                                    annuller
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size={"large"}
                                    style={{ marginTop: 24 }}
                                >
                                    Gem Fangst
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
}

export default observer(NewCatchModal);