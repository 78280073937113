import { FunctionComponent } from "react";

export interface UnitCounterProps {
    count: number | string,
    unit: string,
    style?: React.CSSProperties
}

const UnitCounter: FunctionComponent<UnitCounterProps> = ({ count, unit, style }) => {

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
        } as React.CSSProperties,
        
        amount: {
            fontSize: 24,
            fontWeight: 400,
            letterSpacing: "0.0250rem",
            margin: 0,
        } as React.CSSProperties,

        unit: {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "0.0250rem",
            margin: 0,
            transform: "translateY(-0.250rem)",
            textTransform: "uppercase"
        } as React.CSSProperties,

    };

    return (
        <div style={{...styles.container, ...style}} className="ion-text-center" >
            <p style={styles.amount}>{ count }</p>
            <p style={styles.unit}>{ unit }</p>
        </div>
    );
}

export default UnitCounter;