import { observer } from 'mobx-react-lite';

import * as React from 'react';
import { useEffect, useState } from 'react';

import ReactMapGL, { ViewState, Marker } from "react-map-gl";

import { useStore } from '../../stores/store';
import { Place } from '@mui/icons-material';
import { RouteComponentProps } from 'react-router';

interface MapProps { }

const Map: React.FunctionComponent<MapProps> = () => {
    const { spotStore, uiStore, weatherStore } = useStore();
    const { loadSpots, selectSpot, spotsForMap, selectedSpot } = spotStore;
    const { openSlideupContainer, slideUpContainerIsOpen } = uiStore;
    const { getWeatherFromCoordinates, getWeatherAtMapCenter } = weatherStore;

    const selectedSpotId = selectedSpot.id;

    const [lng, setLng] = useState(10.8824025);
    const [lat, setLat] = useState(56.4099013);
    const [zoom, setZoom] = useState(9);

    const updateViewPort = (viewport: ViewState, interaction: any) => {
        if (interaction.isPanning || interaction.isZoooming) {
            setLng(viewport.longitude);
            setLat(viewport.latitude);
            setZoom(viewport.zoom);
        }
    }

    const updateWeather = () => {
        getWeatherAtMapCenter(lat, lng);
    }

    useEffect(() => {
        loadSpots();
    }, [loadSpots])

    async function triggerSpotSelection(spotId: string) {
        await selectSpot(spotId);
        await getWeatherFromCoordinates(+selectedSpot.latitude, +selectedSpot.longitude)
        if (!slideUpContainerIsOpen) { openSlideupContainer() };
    }

    const styles = {
        mapContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100%",
            zIndex: -1,
        } as React.CSSProperties
    }

    if (spotsForMap === null || spotsForMap === undefined) { return null }
    else {
        return (
            <div style={styles.mapContainer}>
                <ReactMapGL
                    mapboxApiAccessToken={"pk.eyJ1IjoiZGVlYm5keCIsImEiOiJja3c0bWh6NWYwNXN5Mm9yaHA1MjZiNTAzIn0.iM8Hq_r7-UDi1TGBNGg2vA"}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    latitude={lat}
                    longitude={lng}
                    zoom={zoom}
                    width={"100vw"}
                    height={"100%"}
                    onViewportChange={(viewport: ViewState, interaction: any) => updateViewPort(viewport, interaction)}
                    onMouseUp={updateWeather}
                    onTouchEnd={updateWeather}
                >
                    {spotsForMap!.map(x => {
                        return (
                            <Marker
                                key={x.spotId}
                                longitude={+x.longitude}
                                latitude={+x.latitude}
                                onClick={() => triggerSpotSelection(x.spotId)}
                            >
                                {
                                    x.spotId == selectedSpotId ? <Place color={"secondary"} fontSize={"large"} style={{ transform: "translate(-50%, -100%)" }} />
                                        : <Place style={{ transform: "translate(-50%, -100%)" }} />
                                }
                            </Marker>
                        )
                    })}
                </ReactMapGL>
            </div>
        );
    };

}

export default observer(Map);