import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { FeedTrip } from "../models/FeedTrip";

export default class FeedStore {
    loading: boolean = false;
    feedItems: FeedTrip[] | null = [];

    constructor() {
        makeAutoObservable(this);
    }

    getTrips = async () => {        
        this.setLoading(true)
        try {
            runInAction(() => (this.feedItems = null));
            const feedItems = await agent.Feed.list();
            runInAction(() => (this.feedItems = feedItems));
        } catch (err) {
            throw err;
        }
        this.setLoading(false);
    };

    private setLoading = (state: boolean) => this.loading = state; 
}
