import { fontWeight } from "@mui/system";
import { FunctionComponent } from "react";
import { useStore } from "../../stores/store";
import UserAvatar from "./UserAvatar";

interface UserProfileInlineProps {
    onClick?: () => {} | void;
    style?: React.CSSProperties;
}

const UserProfileInline: FunctionComponent<UserProfileInlineProps> = ( { onClick, style } ) => {
    const { accountStore } = useStore();
    const { user } = accountStore;

    const styles = {
        container: {
            display: "flex",
            flexDirection: "row",
            gap: 16,
            alignItems: "center",
            width: "100%"
        } as React.CSSProperties,

        textContainer: {
            display: "flex",
            flexDirection: "column",
        } as React.CSSProperties,

        name: {
            fontSize: 20,
            fontWeight: 500,
            margin: 0,
            letterSpacing: "0.0125rem"
        } as React.CSSProperties,

        homeTown: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "0.0500rem"
        } as React.CSSProperties,


    }

    return (
        <div style={{...styles.container, ...style}} onClick={onClick}>
            <UserAvatar />
            <div style={styles.textContainer}>
                <h2 style={styles.name}> {user?.realName} </h2>
                <h4 style={styles.homeTown}>Danmark</h4>
            </div>
        </div>
    );
}

export default UserProfileInline;