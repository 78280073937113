import { makeAutoObservable } from "mobx"

interface Ui {
  slideUpContainerIsOpen: boolean;
}

export default class UiStore {
    slideUpContainerIsOpen = false

  constructor() {
    makeAutoObservable(this);
  }

  openSlideupContainer = () => {
    this.slideUpContainerIsOpen = true;
  };

  closeSlideupContainer = () => {
    this.slideUpContainerIsOpen = false;
  };
}