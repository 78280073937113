import ImageIcon from '@mui/icons-material/Image';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from "react";
import { UserTripOverviewDto } from '../../models/User';

import { history } from "../../App";
import { Routes } from '../../shared/Routes';
import { useStore } from '../../stores/store';

interface UserTripHistoryProps {
    items: UserTripOverviewDto[] | null;
}

const UserTripHistory: FunctionComponent<UserTripHistoryProps> = ({ items }) => {
    const styles = {
        container: {
            marginTop: 40
        } as React.CSSProperties,

        title: {
            fontSize: 24,
            fontWeight: 400,
            marginBottom: 4
        } as React.CSSProperties,
    };

    if (items) {
        return (
            <div style={styles.container} >

                <h2 style={styles.title}>Historik</h2>

                <List>
                    {
                        items.map(x => {
                            return (
                                <UserTripHistoryItem
                                    key={x.id}
                                    locationName={x.spotName}
                                    catchAmount={x.catchAmount}
                                    imageUrl={x.image}
                                />
                            )
                        })
                    }
                </List>
            </div>
        );
    }
    else {
        return (
            <div style={styles.container} >
                <h2 style={styles.title}>Historik</h2>
                <p>Endnu ingen ture</p>
            </div>
        )
    }
}

interface UserTripHistoryItemProps {
    locationName: string,
    catchAmount: string | number,
    imageUrl?: string
    onClick?: () => {} | void;
}

const UserTripHistoryItem: FunctionComponent<UserTripHistoryItemProps> = ({ locationName, catchAmount, imageUrl, onClick }) => {
    return (
        <ListItem onClick={onClick}>
            <ListItemAvatar>

                {
                    imageUrl ? <Avatar src={imageUrl} />
                        : <Avatar> <ImageIcon /> </Avatar>
                }

            </ListItemAvatar>
            <ListItemText primary={locationName} secondary={`${catchAmount} fisk fanget`} />
        </ListItem>
    );
}

export default observer(UserTripHistory);