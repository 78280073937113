import { Air, Thermostat, VerticalAlignBottom, Straighten } from '@mui/icons-material';
import React, { FunctionComponent } from 'react';
import OptimalConditions from '../../models/OptimalConditions';
import ConditionsItem from './ConditionItem';
import unitsOfMeasurement from '../../shared/enums/unitsOfMeasurement';
import { observer } from "mobx-react-lite";
import { useStore } from '../../stores/store';

interface CondtionsProps {
    conditions: OptimalConditions;
    minLengthOfFish: number | any
}

const Conditions: FunctionComponent<CondtionsProps> = ({ conditions, minLengthOfFish }) => {  
    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 28
        }}>
            <ConditionsItem
                unit={unitsOfMeasurement.UnitTypes.wind}
                value={`${conditions?.minimumWindInMetersPerSecond}-${conditions?.maximumWindInMetersPerSecond}`}
                measurement={unitsOfMeasurement.WindMeasurementTypes.metersPerSecond}
                icon={<Air />}
            />
            <ConditionsItem
                unit={unitsOfMeasurement.UnitTypes.temperautre}
                value={`${conditions?.minimumTemperatureInCelcius}-${conditions?.maximumTemperatureInCelcius}`}
                measurement={unitsOfMeasurement.TemperatureMeasurementTypes.celcius}
                icon={<Thermostat />}
            />
            <ConditionsItem
                unit={unitsOfMeasurement.UnitTypes.depth}
                value={`${conditions?.minumumDepth}-${conditions?.maximumDepth}`}
                measurement={unitsOfMeasurement.DistanceMeasuremntTypes.meters}
                icon={<VerticalAlignBottom />}
            />
            <ConditionsItem
                unit={unitsOfMeasurement.UnitTypes.minLength}
                value={`${minLengthOfFish}`}
                measurement={unitsOfMeasurement.DistanceMeasuremntTypes.centimeters}
                icon={<Straighten />}
            />
        </div>
    );
}

export default observer(Conditions);