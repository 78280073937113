import { IonContent, IonFab, IonFabButton, IonFabList, IonIcon, IonPage } from '@ionic/react';
import { add, location } from "ionicons/icons";
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from 'react-router';
import Map from "../components/Map/Map";
import Slideup from "../components/Slideup/Index";
import UserAvatar from "../components/User/UserAvatar";
import WeatherBar from "../components/WeatherBar";
import { useStore } from "../stores/store";
import { history } from "../App";
import { Routes } from '../shared/Routes';

mapboxgl.accessToken = `pk.eyJ1IjoiZGVlYm5keCIsImEiOiJja3c0bWh6NWYwNXN5Mm9yaHA1MjZiNTAzIn0.iM8Hq_r7-UDi1TGBNGg2vA`;

const HomeScreen: React.FC<RouteComponentProps> = (props) => {
    const { uiStore, accountStore } = useStore();
    const { slideUpContainerIsOpen } = uiStore;
    const { user } = accountStore;

    const styles = {
        mapContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100%",
            zIndex: -1,
        } as React.CSSProperties,

        topBar: {
            display: "grid",
            gridTemplateColumns: "1fr max-content 1fr",
            alignItems: "center",
            // maxWidth: 600,
            margin: "8px auto",
            padding: "0 8px"
        } as React.CSSProperties,
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                {/* <IonSearchbar clearIcon={undefined} /> */}

                <div style={styles.topBar} >
                    <div></div>
                    <WeatherBar />
                    <div style={{ justifySelf: "end", cursor: "pointer"}}>
                        <UserAvatar imageUrl={user?.image} userName={user?.realName} />
                    </div>
                </div>

                <Map />

                {/* <IonFab hidden={slideUpContainerIsOpen} vertical="bottom" horizontal="end" slot="fixed" >
                    <IonFabButton>
                        <IonIcon icon={add} />
                    </IonFabButton>
                    <IonFabList side="top" >
                        <IonFabButton>
                            <IonIcon icon={add} />
                        </IonFabButton>
                        <IonFabButton>
                            <IonIcon icon={location} />
                        </IonFabButton>
                    </IonFabList>
                </IonFab> */}
            </IonContent>
            <Slideup {...props} />
        </IonPage>
    );
};

export default observer(HomeScreen);
