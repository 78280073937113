import { Logout, Event, Place } from "@mui/icons-material";
import { FunctionComponent } from "react";
import { UserBestCatchDto } from "../../models/User";
import StaticImageWithOverlay from "../Images/StaticImageWithOverlay";
import { format } from "date-fns";


interface BestCatchProps {
    userCatch: UserBestCatchDto | null;
}

const BestCatch: FunctionComponent<BestCatchProps> = ({ userCatch }) => {
    const styles = {
        container: {
            marginTop: 64,
        } as React.CSSProperties,

        title: {
            fontSize: 24,
            fontWeight: 400,
            marginBottom: 4
        } as React.CSSProperties,

        row: {
            display: "flex",
            flexDirection: "row",
            gap: 12,
            marginTop: 4,
        } as React.CSSProperties,

        fishInfo: {
            fontSize: 16,
            fontWeight: 700,
        } as React.CSSProperties,

        dataWIcon: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: "0.0250rem",
            gap: 6,
            marginRight: 4,
        } as React.CSSProperties,
    };

    if (userCatch) {
        return (
            <div style={styles.container}>
                <h2 style={styles.title}>Bedste fangst</h2>

                <StaticImageWithOverlay
                    imageUrl={"https://images.unsplash.com/photo-1574781330855-d0db8cc6a79c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1185&q=80"}
                >
                    <div style={{ ...styles.row, ...styles.fishInfo }} >
                        <p> {userCatch.fishSpeciesName} </p>
                        •
                        <p> {userCatch.fishWeightInKilograms.toString().replace(".", ",")} kg </p>
                        •
                        <p> {userCatch.fishLengthInCentimeters.toString().replace(".", ",")} cm</p>
                    </div>
                    <div style={{ ...styles.row }} >
                        <div style={{ ...styles.dataWIcon }} >
                            <Event fontSize="small" />
                            {format(new Date(userCatch.date), "dd-MM-yyyy")}
                        </div>
                        <div style={{ ...styles.dataWIcon }} >
                            <Place fontSize="small" />
                            {userCatch.spotName}
                        </div>
                    </div>
                </StaticImageWithOverlay>
            </div>
        );
    }
    else {
        return null
    }
}

export default BestCatch;