import * as React from 'react';
import { FunctionComponent } from 'react';

interface ConditionsItemProps {
    unit: string,
    value: string,
    measurement: any,
    icon?: any
}
 
const ConditionsItem: FunctionComponent<ConditionsItemProps> = ({ unit, value, measurement, icon}) => {

    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
        } as React.CSSProperties,
        
        textContent: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 4
        } as React.CSSProperties,
    }
    
    return ( 
        <div style={styles.container} >
            {icon}
            <div style={styles.textContent}>
                <p style={{ fontSize: 14, fontWeight: "500" }}>{value.toString()} {measurement}</p>
                <p style={{fontSize: 12}}>{unit}</p>
            </div>
        </div>
     );
}
 
export default ConditionsItem;