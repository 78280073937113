import { IonSpinner } from '@ionic/react';
import { Air, CompassCalibration, Explore, LocationOn, Thermostat } from '@mui/icons-material';
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from 'react';
import { localStorageName } from '../../shared/enums/localStorage';
import { useStore } from '../../stores/store';
import unitsOfMeasurement from '../../shared/enums/unitsOfMeasurement';
import ConditionsItem from '../Conditions/ConditionItem';

interface WeatherBarProps { };

const WeatherBar: FunctionComponent<WeatherBarProps> = () => {

    const { weatherStore } = useStore();
    const { loadingWeather, weatherAtMapCenter } = weatherStore;

    const styles = {
        container: {
            color: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "6px 16px",
            // margin: "8px auto 0 auto",
            borderRadius: 6,
            background: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(6px)",
            maxWidth: 480
        } as React.CSSProperties,

        weather: {
            display: "flex",
            gap: 24,
        } as React.CSSProperties,

        loader: {
            color: "#fff",
            display: "flex",
            alignItems: "center"
        } as React.CSSProperties,

    }

    const stringifiedCoords = localStorage.getItem(localStorageName.mapCenterStoredCoordinates)

    let coords;

    if (stringifiedCoords) {
        coords = JSON.parse(stringifiedCoords)
    }

    return (
        <div style={styles.container}>
            <div style={styles.weather}>

                {weatherAtMapCenter != undefined ? <>

                    <ConditionsItem
                        unit={unitsOfMeasurement.UnitTypes.wind}
                        value={`${weatherAtMapCenter.windSpeed}`}
                        measurement={unitsOfMeasurement.WindMeasurementTypes.metersPerSecond}
                        icon={<Air />}
                    />
                    <ConditionsItem
                        unit={unitsOfMeasurement.UnitTypes.temperautre}
                        value={`${weatherAtMapCenter.airTemperature}`}
                        measurement={unitsOfMeasurement.TemperatureMeasurementTypes.celcius}
                        icon={<Thermostat />}
                    />

                    {
                        coords ?
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                <ConditionsItem
                                    unit={""}
                                    value={`${coords.lat.toFixed(2)}, ${coords.lon.toFixed(2)}`}
                                    measurement={""}
                                    icon={<Explore />}
                                />
                            </div>
                            : null
                    }

                </> : null
                }
            </div>

            <div style={styles.loader}>
                {
                    loadingWeather ?? <IonSpinner name="crescent" />
                }
            </div>

        </div>
    );
}

export default observer(WeatherBar);