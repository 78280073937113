import Lottie from "lottie-react";
import React, { FunctionComponent } from 'react';
import fishAnimation from "../../assets/fish.json";


interface FishAnimationProps { };

const FishAnimation: FunctionComponent<FishAnimationProps> = () => {

    const styles = {
        container: {
            // width: "117vw",
            // transform: "translate(-10%, -28%)",
            zIndex: "-1"
        } as React.CSSProperties
    }

    return (
        <div style={styles.container}>
            <Lottie animationData={fishAnimation} autoPlay={true} autoplay={true} />
        </div>
    );
}

export default FishAnimation;