import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../App";
import Catch, { CatchEdit } from "../models/Catch";
import { FeedTrip } from "../models/FeedTrip";
import FishSelect from "../models/FishSelect";
import { FishSpecies } from "../models/FishSpecies";
import { Spot } from "../models/Spot";
import SpotWithFishIdDTO from "../models/SpotWithFishIdDTO";
import Trip, { TripSubmit } from "../models/Trip";
import { User, UserBestCatchDto, UserFormValues, UserProfileDto, UserStatsDto, UserTripOverviewDto } from "../models/User";
import YrResponse from "../models/YrWeather";
import { Routes } from "../shared/Routes";
import { store } from "../stores/store";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = "http://localhost:5000/api/";
// axios.defaults.baseURL = "/api/";
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const tokenInterceptor = axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store.commonStore.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`;

    return config;
  }
);

axios.interceptors.response.use(
  async (response) => {
    // await sleep(1500);
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === "string") {
          toast.error(data);
        }
        break;

      case 401:
        toast.error("Uautoriseret adgang");
        break;

      case 404:
        toast.error("Vi kunne ikke finde hvad du ledte efter");
        history.push(`/${Routes.NotFoundScreen}`);
        break;

      case 500:
        store.commonStore.setServerError(data);
        history.push(`/${Routes.ServerErrorScreen}`);
        break;
    }

    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
// const responseHeader = (response: AxiosResponse) => response.headers

const requests = {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) =>
    axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Spots = {
  list: () => requests.get<SpotWithFishIdDTO[]>("/FishingSpot/"),
  details: (id: string) => requests.get<Spot>(`/FishingSpot/${id}`),
  //   renderMapSpots: (body: MapBoundaries) => requests.post<Spot[]>("/Spot/GetFromMap/", body),
};

const Fish = {
  listAcademy: () => requests.get<FishSpecies[]>("/FishSpecies"),
  details: (id: string) => requests.get<FishSpecies>(`/FishSpecies/${id}`),
};

const Weather = {
  get: (lat: number, lon: number, lastModified?: any) => {
    axios.interceptors.request.eject(tokenInterceptor);

    return axios
      .get<YrResponse>(
        `https://api.met.no/weatherapi/locationforecast/2.0/compact?altitude=0&lat=${lat}&lon=${lon}`,
        {
          headers: {
            "User-Agent": "https://github.com/DeeBndx/fishy",
          },
        }
      )
      .then((response) => response);
  },
};

const Account = {
  current: () => requests.get<User>("/account"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  register: (user: UserFormValues) => requests.post<User>("/account/register", user),
};

const Users = {
  selectedUser: (id: string) => requests.get<UserProfileDto>(`/UserProfile/${id}`),
  getUsersStats: (id: string) => requests.get<UserStatsDto[]>(`/UserProfile/stats/${id}`),
  getUsersBestCatch: (id: string) => requests.get<UserBestCatchDto>(`/UserProfile/best/${id}`),
  getUserTripHistory: (id: string) => requests.get<UserTripOverviewDto[]>(`/UserProfile/history/${id}`),
};

const Feed = {
    list: () => requests.get<FeedTrip[]>("/feed"),
}

const Trips = {
    listFish: () => requests.get<FishSelect[]>("/Trip"),
    createTrip: (trip: TripSubmit) => requests.post<TripSubmit>(`/Trip`, trip),
    getTrip: (tripId: string) => requests.get<Trip>(`/Trip/GetTrip/${tripId}`),
    // getCatches: (tripId: string) => requests.get<Catch[]>(`/Trip/GetCatches/${tripId}`),
    getCatch: (catchId: string) => requests.get<CatchEdit>(`/Trip/${catchId}`),
    updateCatch: (_catch: CatchEdit) => requests.patch<CatchEdit>(`/Trip`, _catch),
    deleteCatch: (catchId: string) => requests.del<any>(`/Trip/${catchId}`),
};

export default {
  Spots,
  Fish,
  Weather,
  Account,
  Users,
  Feed,
  Trips,
};