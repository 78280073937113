import { IonContent, IonLoading, IonPage, IonTitle } from '@ionic/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import FeedListItem from '../components/Feed/FeedListItem';
import { useStore } from '../stores/store';

interface FeedScreenProps extends RouteComponentProps<{}> {

}

const FeedScreen: FunctionComponent<FeedScreenProps> = ({ match, location, history }) => {
    const { feedStore } = useStore();
    const { getTrips, feedItems, loading } = feedStore;

    useEffect(() => {
        getTrips();
    }, [getTrips])

    if (feedItems === null || feedItems === undefined || loading) {
        return (
            <IonContent>
                <IonLoading isOpen={true} spinner={"crescent"} backdropDismiss={true} />
            </IonContent>
        )
    }
    else {
        return (
            <IonPage>
                <IonContent>

                    {
                        feedItems.map(x => {
                            return (
                                <>
                                    <FeedListItem
                                        key={x.tripId}
                                        feedItem={x}
                                        history={history}
                                        location={location}
                                        match={match}
                                    />
                                </>
                            )
                        })
                    }

                </IonContent>
            </IonPage>
        );
    }

}

export default observer(FeedScreen);