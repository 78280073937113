import { FunctionComponent } from "react";
import { UserStatsDto } from "../../models/User";
import UnitCounter, { UnitCounterProps } from "./UnitCounter";

interface UnitCounterContainerProps {
    items: UnitCounterProps[] | UserStatsDto[] | null
}

const UnitCounterContainer: FunctionComponent<UnitCounterContainerProps> = ({ items }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            marginTop: 40
        }}
        >
            {
                items ? items?.map(x => {
                    return (
                        <UnitCounter key={`${x.count} ${x.unit}`} count={x.count} unit={x.unit} />
                    )
                }) : null
            }
        </div>
    );
}

export default UnitCounterContainer;