import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { MapBoundaries } from "../models/MapBoundaries";
import { Spot } from "../models/Spot";
import SpotWithFishEssentialInfoDTO from "../models/SpotWithFishEssentialInfoDTO";
import SpotWithFishIdDTO from "../models/SpotWithFishIdDTO";
import { placeholderSpot } from "../shared/placeholders";

export default class SpotStore {
    spotRegistry = new Map<string, Spot>();
    spotsForMap: SpotWithFishIdDTO[] = [];
    selectedSpot: Spot = placeholderSpot;
    spotForTrip: any;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadSpots = async () => {
        this.setLoading(true);

        try {
            const spots = await agent.Spots.list();

            let newArray: SpotWithFishIdDTO[] = [];

            spots.forEach((spot) => {
                newArray.push(spot);
            });

            runInAction(() => {
                this.spotsForMap = newArray;
            });
        } catch (err) {
            console.log(err);
        }
        this.setLoading(false);
    };

    selectSpot = async (id: string) => {
        const spotToBeSelected = this.spotsForMap!.find((x) => x.spotId === id);

        if (spotToBeSelected == undefined) return;

        try {
            runInAction(async () => {
                this.selectedSpot = await agent.Spots.details(spotToBeSelected.spotId);
            });
        } catch (err) {
            console.log(err);
        }
    };

    resetSelectedSpot = async () => {
        runInAction(() => {
            this.selectedSpot = placeholderSpot;
        });
    };

    private setSpot = (spot: Spot) => {
        this.spotRegistry.set(spot.id, spot);
    };

    private getSpot = (id: string) => {
        return this.spotRegistry.get(id);
    };

    private setLoading = (state: boolean) => (this.loading = state);
}
