import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
    UserBestCatchDto,
    UserProfileDto,
    UserStatsDto,
    UserTripOverviewDto,
} from "../models/User";

export default class UsersStore {
    userProfile: UserProfileDto | null = null;
    stats: UserStatsDto[] | null = null;
    bestCatch: UserBestCatchDto | null = null;
    tripHistory: UserTripOverviewDto[] | null = [];

    constructor() {
        makeAutoObservable(this);
    }

    getUserProfile = async (userId: string) => {
        try {
            const user = await agent.Users.selectedUser(userId);
            runInAction(() => (this.userProfile = user));
        } catch (err) {
            throw err;
        }
    };

    getUserStats = async (userId: string) => {
        try {
            const userStats = await agent.Users.getUsersStats(userId);
            runInAction(() => (this.stats = userStats));
        } catch (err) {
            console.log(err);
            //   throw err;
        }
    };

    getUserBestCatch = async (userId: string) => {
        try {
            const userCatch = await agent.Users.getUsersBestCatch(userId);
            runInAction(() => (this.bestCatch = userCatch));
        } catch (err) {
            console.log(err);
            //   throw err;
        }
    };

    getUserTripHistory = async (userId: string) => {
        try {
            const userHistory = await agent.Users.getUserTripHistory(userId);
            runInAction(() => (this.tripHistory = userHistory));
        } catch (err) {
            console.log(err);
            //   throw err;
        }
    };
}
