import Catch from "../models/Catch";
import OptimalConditions from "../models/OptimalConditions";

export enum urls {
    image = "https://images.unsplash.com/photo-1574781330855-d0db8cc6a79c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1485&q=80",
}

export const placeholderConditions: OptimalConditions = {
    id: "0000-0000-0000-0000",
    minimumWindInMetersPerSecond: 0,
    maximumWindInMetersPerSecond: 8,
    minimumTemperatureInCelcius: 0,
    maximumTemperatureInCelcius: 12,
    minumumDepth: 0,
    maximumDepth: 18,
};

export const placeholderSpot = {
    id: "0000-0000-0000-0000",
    name: "",
    latitude: "56.4297908",
    longitude: "10.6993499",
    fishSpecies: {
        id: "1111-1111-1111-1111",
        name: "",
        latinName: "",
        imageUrl: "",
        articleBodyAsMarkdown: "",
        quickCatchText: "",
        fishRules: [
            {
                id: "3333-3333-3333-3333",
                minLengthOfFish: 0,
            },
            {
                id: "4444-4444-4444-4444",
                minLengthOfFish: 0,
            },
        ],
        fishBulletPoints: undefined,
        optimalConditions: {
            id: "2222-2222-2222-2222",
            minimumWindInMetersPerSecond: 0,
            maximumWindInMetersPerSecond: 0,
            minimumTemperatureInCelcius: 0,
            maximumTemperatureInCelcius: 0,
            minumumDepth: 0,
            maximumDepth: 0,
        },
    },
};