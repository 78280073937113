import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FishSpecies } from '../../models/FishSpecies';
import { observer } from "mobx-react-lite";
import { useStore } from '../../stores/store';
import { Check } from '@mui/icons-material';
import { Card, CardHeader, Avatar, CardMedia, CardContent, Typography, Button, CardActions } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { format } from 'path';
import { urls } from '../../shared/placeholders';

interface AcademyListItemProps extends RouteComponentProps<{}> {
    fish: FishSpecies
}

const AcademyListItem: React.FunctionComponent<AcademyListItemProps> = ({ fish, history }) => {
    return (
        <Card
            sx={{ maxWidth: 540, width: "92vw", margin: "16px auto" }}
            onClick={() => history.push(`/academy/fish/${fish.id}`)} >
            <CardHeader
                title={fish.name}
                subheader={fish.latinName}
            />
            <CardMedia
                component="img"
                height={200}
                image={fish.imageUrl}
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                    {fish.quickCatchText.substring(0, 200)}...
                </Typography>
            </CardContent>

            <CardActions>
                <Button size="medium">Læs Mere</Button>
            </CardActions>
        </Card>
    );
}

export default observer(AcademyListItem);