import { IonButton, IonContent, IonImg, IonText } from '@ionic/react';
import { Button } from '@mui/material';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Routes, subRoutes } from '../../shared/Routes';
import { useStore } from '../../stores/store';
import Conditions from '../Conditions';

interface SlideupProps extends RouteComponentProps<{}> {
}

const Slideup: FunctionComponent<SlideupProps> = ({ match, location, history }) => {

    const { spotStore, uiStore, tripStore, accountStore } = useStore();
    const { resetSelectedSpot, selectedSpot } = spotStore;
    const { closeSlideupContainer, slideUpContainerIsOpen } = uiStore;
    const { startNewTrip } = tripStore;
    const { isLoggedIn } = accountStore;

    const styles = {
        container: {
            display: 'flex',
            flexDirection: "column",
            background: "#fff",
            padding: "0 16px 16px 16px",
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: "100%",
            transition: "300ms",
            transform: slideUpContainerIsOpen ? "translateY(0%)" : "translateY(100%)",
            color: "black",
        } as React.CSSProperties,

        pullHandle: {
            margin: "16px auto",
            width: 24,
            height: 4,
            background: "#000",
            opacity: .6,
            borderRadius: 8,
        } as React.CSSProperties,

        closer: {
            alignSelf: "end",
            padding: 24
        } as React.CSSProperties,

        imgContainer: {
            maxHeight: 320,
            maxWidth: 320,
            alignSelf: "center"
        } as React.CSSProperties,

        heading: {
            marginTop: 0,
            fontSize: 20
        } as React.CSSProperties,

        titleBox: {

        } as React.CSSProperties,

        howToCatchTextContainer: {

        } as React.CSSProperties,

    }

    const closeFishPreview = () => {
        resetSelectedSpot();
        closeSlideupContainer();
    }

    const initiateNewTrip = (id: string, lat: string, lon: string) => {
        startNewTrip(id, lat, lon);
    }

    return (
        <div style={styles.container}>
            {/* TODO: Implement dragging logic */}
            {/* <div style={styles.pullHandle}></div> */}

            <div style={styles.closer}>
                <IonButton children={"Luk"} fill={"clear"} onClick={closeFishPreview} />
            </div>

            {
                selectedSpot ?
                    <>
                        <div style={styles.imgContainer}>
                            <IonImg src={selectedSpot?.fishSpecies?.imageUrl} />
                        </div>
                        <div style={styles.titleBox}>
                            <IonText>
                                <p style={{ fontSize: 12 }}>{selectedSpot?.name}</p>
                                <h1 style={styles.heading}>{selectedSpot?.fishSpecies?.name} <i style={{ opacity: .6, fontWeight: 400, fontSize: 18 }}> - {selectedSpot?.fishSpecies?.latinName}</i> </h1>
                            </IonText>
                        </div>

                        <Conditions
                            conditions={selectedSpot!.fishSpecies!.optimalConditions}
                            minLengthOfFish={selectedSpot.fishSpecies?.fishRules![0].minLengthOfFish}
                        />

                        {
                            isLoggedIn ?
                                <div style={{ width: "100%", marginBottom: 20 }}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => initiateNewTrip(selectedSpot.id, selectedSpot.latitude, selectedSpot.longitude)}
                                    >
                                        Start Tur her
                                    </Button>
                                </div> : null
                        }

                        <div style={styles.howToCatchTextContainer}>
                            <IonText>
                                <h2 style={styles.heading}>Sådan fanger du...</h2>
                                <div style={{ maxWidth: "100%" }}>
                                    <IonText>
                                        <p> {selectedSpot?.fishSpecies?.quickCatchText} </p>
                                    </IonText>
                                </div>
                            </IonText>

                        </div>

                        <div style={{ marginTop: 40, width: "100%" }}>
                            {/* TODO: Navigate to correct academy page */}
                            <IonButton
                                expand={"block"}
                                onClick={() => history.push(`/${Routes.AcademyScreen}/${subRoutes.fishDetails}/${selectedSpot!.fishSpecies!.id}`)}
                            >
                                LÆR MERE OM {selectedSpot?.fishSpecies?.name}
                            </IonButton>
                        </div>
                    </>
                    : null
            }

        </div>
    );
}

export default observer(Slideup);