import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { AccessTime, Check, Close } from "@mui/icons-material";
import { AppBar, Box, Button, Modal, Toolbar, Typography } from "@mui/material";
import { common, red } from "@mui/material/colors";
import { format } from "date-fns";
import { add } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { history } from "../App";
import StaticMap from "../components/Map/StaticMap";
import CatchList from "../components/NewTrip/CatchList";
import NewCatchModal from "../components/NewTrip/NewCatchModal";
import { Routes } from "../shared/Routes";
import { useStore } from "../stores/store";

interface NewTripScreenProps extends RouteComponentProps<{}> {

}

const NewTripScreen: FunctionComponent<NewTripScreenProps> = () => {
    const { tripStore, accountStore } = useStore();
    const { submitTrip, cancelTrip, addNewCatch, getFishListForSelect, catches, latitude, longitude, tripStart } = tripStore;
    const { user } = accountStore;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);

    useEffect(() => {
        getFishListForSelect();
    }, [getFishListForSelect])

    const styles = {
        tripData: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            maxWidth: 540,
            width: "92vw",
            margin: "20px auto"
        } as React.CSSProperties,

        dataPoint: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
        } as React.CSSProperties,
    };

    const cancelModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "92vw",
        maxWidth: 540,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        outline: 0,
        borderRadius: 2,
        color: "black",
    };

    const saveTrip = async () => {
        await submitTrip();
        history.push(`/${Routes.Profile}/${user?.id}`);
    }

    const cancelTripAndRedirect = () => {
        setCancelModalIsOpen(false);
        cancelTrip();
    }

    return (
        <IonPage>
            <IonContent>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Ny Tur
                            </Typography>
                            <Button color="inherit" onClick={() => setCancelModalIsOpen(true)}> <Close /> </Button>
                            <Button color="inherit" onClick={saveTrip}> <Check /> </Button>
                        </Toolbar>
                    </AppBar>
                </Box>

                <StaticMap
                    lat={+latitude}
                    lon={+longitude}
                />

                <div style={styles.tripData}>
                    <div style={styles.dataPoint}>
                        <AccessTime />
                        <p> {format(new Date(tripStart), "HH:mm")} </p>
                    </div>
                </div>
                <hr style={{ backgroundColor: common.black, opacity: 0.12, width: "92vw", maxWidth: 540, margin: "0 auto" }} />

                <CatchList />

                <Modal
                    open={cancelModalIsOpen}
                    onClose={() => setCancelModalIsOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={cancelModalStyle}>
                        <div
                            style={{
                                textAlign: "center",
                                marginBottom: 20
                            }}
                        >
                            <h4>Vil du annullere turen?</h4>
                            <p>OBS!: alle indtastede fangster vil gå tabt</p>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                            <Button
                                onClick={cancelTripAndRedirect}
                                variant="contained"
                                sx={{
                                    bgcolor: red[50], color: red[400],
                                    ":hover": {
                                        bgcolor: red[100],
                                        color: red[600]
                                    }
                                }}>
                                Annuller tur
                            </Button>
                            <Button
                                onClick={() => setCancelModalIsOpen(false)}
                                variant="text"
                            >
                                Forsæt tur
                            </Button>
                        </div>
                    </Box>
                </Modal>

                <NewCatchModal
                    open={modalIsOpen}
                    handleClose={() => setModalIsOpen(false)}
                />

                <IonFab vertical="bottom" horizontal="end" slot="fixed" >
                    <IonFabButton onClick={() => setModalIsOpen(true)}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
}

export default observer(NewTripScreen);