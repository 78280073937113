import { Email, Lock } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { history } from '../../App';
import { Routes } from "../../shared/Routes";
import { useStore } from '../../stores/store';

interface LoginFormProps {

}

const LoginForm: FunctionComponent<LoginFormProps> = () => {
    const { accountStore } = useStore();

    const styles = {
        inputContainer: {
            margin: "10px 0",
        } as React.CSSProperties,

        shifterContainer: {
            zIndex: "1",
            margin: "60px 0 16px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        } as React.CSSProperties,

        shifterLabel: {
            fontSize: 10,
            fontWeight: 500,
            letterSpacing: 3.5,
            marginBottom: 8,
            textTransform: "uppercase"
        } as React.CSSProperties,
    }

    return (
        <div style={{ zIndex: "4", position: "relative" }}>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    error: null
                }}
                onSubmit={(values, { setErrors }) => accountStore.login(values).catch(error => setErrors({ error: "Ugyldig Email eller kodeord" }))}
            >

                {({ handleSubmit, handleChange, isSubmitting, errors }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off" style={{ zIndex: 1 }}>
                        <TextField
                            style={{ margin: "16px 0" }}
                            name="email"
                            placeholder="Email"
                            label="Email"
                            inputMode="email"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"> <Email /> </InputAdornment>
                            }}
                            onChange={handleChange}
                        />
                        <TextField
                            style={{ margin: "16px 0" }}
                            name="password"
                            placeholder="Kodeord"
                            label="Kodeord"
                            type="password"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"> <Lock /> </InputAdornment>
                            }}
                            onChange={handleChange}
                        />
                        <ErrorMessage
                            name="error" render={() => <p style={{ marginBottom: 16, color: "#FB2626" }}>{errors.error}</p>}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth={true}
                            size={"large"}
                            style={{ marginTop: 24 }}
                            sx={{
                                bgcolor: deepPurple[500], color: deepPurple[50], transition: "225ms",
                                ":hover": {
                                    bgcolor: deepPurple[800],
                                    color: "white"
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Form>
                )}
            </Formik>


            <div style={styles.shifterContainer}>
                <p style={styles.shifterLabel} >HAR DU BRUG FOR EN KONTO?</p>
                <Button
                    variant="outlined"
                    onClick={() => history.push(`/${Routes.RegisterScreen}`)}
                    sx={{
                        borderColor: deepPurple[500], color: deepPurple[500], transition: "225ms",
                        ":hover": {
                            bgcolor: deepPurple[50]
                        }
                    }}
                >
                    Opret en konto
                </Button>
            </div>
        </div>
    );
}

export default observer(LoginForm);