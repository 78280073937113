import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonItemDivider, IonLabel, IonList, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStore } from '../../stores/store';
import { observer } from "mobx-react-lite";
import unitsOfMeasurement from '../../shared/enums/unitsOfMeasurement';
import { ArrowRightAlt, ChevronLeft, Straighten } from '@mui/icons-material';
import { chevronBackOutline } from 'ionicons/icons';
import { format, formatISO } from "date-fns";
import ConditionsItem from '../Conditions/ConditionItem';

interface AcademyFishDetailsProps extends RouteComponentProps<{ id: string }> {
}

const AcademyFishDetails: FunctionComponent<AcademyFishDetailsProps> = ({ match }) => {
    const { academyStore } = useStore();
    const { selectFish, selectedFish, loading } = academyStore;

    useEffect(() => {
        selectFish(match.params.id)
    }, [selectFish])

    const styles = {
        section: {
            marginBottom: 40,
        } as React.CSSProperties,

        subHeader: {
            fontSize: 20,
            fontWeight: 500,
        } as React.CSSProperties,

        img: {
            width: "100%",
            height: 180,
            background: `url(${selectedFish?.imageUrl})`,
            margin: "40px 0px"
        } as React.CSSProperties,

        bestChanceHeader: {
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 0
        } as React.CSSProperties,

        bestChanceBody: {
            fontSize: 16,
            fontWeight: 400,
            marginTop: 0
        } as React.CSSProperties,
    }

    const ruleTimeZones = {
        start: new Date(selectedFish?.fishRules![0].timeFrameStart!),
        end: new Date(selectedFish?.fishRules![0].timeFrameEnd!)
    }



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/academy" icon={chevronBackOutline} />
                    </IonButtons>
                    <IonTitle>
                        {selectedFish?.name}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {
                    loading ? <IonLoading isOpen={true} spinner="crescent" backdropDismiss={true} /> : <>
                        <IonTitle>
                            <h1 style={{ fontSize: 48, fontWeight: 400, marginBottom: 0 }}>
                                {selectedFish?.name}
                            </h1>
                            <h2 style={{ ...styles.subHeader, marginTop: 0 }}>
                                {selectedFish?.latinName}
                            </h2>
                        </IonTitle>
                        <div className="centeredBackgroundImage" style={styles.img} ></div>


                        {
                            selectedFish?.fishBulletPoints && <div style={styles.section} className="ion-padding ion-margin-vertical">
                                <h2 style={{ ...styles.subHeader, marginTop: 0 }}>
                                    Bedste chance for fangst
                                </h2>
                                <IonGrid style={{ padding: 0 }}>
                                    <IonRow>
                                        <IonCol style={{ paddingLeft: 0 }} size="6">
                                            <h4 style={styles.bestChanceHeader}>Bund</h4>
                                            <p style={styles.bestChanceBody}>{selectedFish?.fishBulletPoints.seaBed}</p>
                                        </IonCol>
                                        <IonCol style={{ paddingLeft: 0 }} size="6">
                                            <h4 style={styles.bestChanceHeader}>Sæson</h4>
                                            <p style={styles.bestChanceBody}>{selectedFish?.fishBulletPoints.season}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol style={{ paddingLeft: 0 }} size="6">
                                            <h4 style={styles.bestChanceHeader}>Grej</h4>
                                            <p style={styles.bestChanceBody}>{selectedFish?.fishBulletPoints.gear}</p>
                                        </IonCol>
                                        <IonCol style={{ paddingLeft: 0 }} size="6">
                                            <h4 style={styles.bestChanceHeader}>Vand</h4>
                                            <p style={styles.bestChanceBody}>{selectedFish?.fishBulletPoints.water}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        }
                        {
                            selectedFish?.fishRules && <div style={styles.section} className="ion-padding ion-margin-vertical">
                                <h2 style={{ ...styles.subHeader, marginTop: 0 }}>
                                    Regler og fredning
                                </h2>

                                <IonList style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    {
                                        selectedFish?.fishRules.map(x => {

                                            const start = new Date(x.timeFrameStart!);
                                            const end = new Date(x.timeFrameEnd!);

                                            let timeFrameDisplay;

                                            if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth()) {
                                                timeFrameDisplay = <span>Hele året </span>
                                            }
                                            else {
                                                timeFrameDisplay = (
                                                    <span style={{
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        {format(start, "dd. MMM")} <ArrowRightAlt /> {format(end, "dd. MMM")}
                                                    </span>
                                                )
                                            }

                                            return (
                                                <div key={x.id}>
                                                    <IonText>
                                                        <h2
                                                            style={{
                                                                ...styles.bestChanceHeader,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            {timeFrameDisplay}
                                                        </h2>
                                                        <p style={{ ...styles.bestChanceBody, marginBottom: 8 }}> {x.notesOnRules} </p>
                                                    </IonText>

                                                    <ConditionsItem
                                                        unit={unitsOfMeasurement.UnitTypes.minLength}
                                                        value={x.minLengthOfFish.toString()}
                                                        measurement={unitsOfMeasurement.DistanceMeasuremntTypes.centimeters}
                                                        icon={<Straighten />}
                                                    />
                                                    <IonItemDivider />
                                                </div>
                                            )
                                        })
                                    }
                                </IonList>

                            </div>
                        }

                        {
                            selectedFish?.quickCatchText && <div style={styles.section} className="ion-padding ion-margin-vertical">
                                <h2 style={{ ...styles.subHeader, marginTop: 0 }}>
                                    Sådan fanger du
                                </h2>
                                <p style={styles.bestChanceBody}> {selectedFish?.quickCatchText} </p>

                            </div>
                        }

                    </>
                }
            </IonContent>


        </IonPage>
    );

}

export default observer(AcademyFishDetails);