import { IonLoading } from '@ionic/react';
import { Email, Lock, Person, Smartphone } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import * as Yup from "yup";
import { history } from "../../App";
import { Routes } from "../../shared/Routes";
import { useStore } from '../../stores/store';

interface RegisterFormProps { };

const RegisterForm: FunctionComponent<RegisterFormProps> = () => {
    const { accountStore } = useStore();

    const styles = {
        inputContainer: {
            margin: "10px 0",
        } as React.CSSProperties,

        shifterContainer: {
            zIndex: "1",
            margin: "60px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        } as React.CSSProperties,

        shifterLabel: {
            fontSize: 10,
            fontWeight: 500,
            letterSpacing: 3.5,
            marginBottom: 8,
            textTransform: "uppercase"
        } as React.CSSProperties,
    }

    return (
        <div style={{ zIndex: "4", position: "relative" }}>
            <Formik
                initialValues={{
                    userName: "",
                    realName: "",
                    email: "",
                    password: "",
                    error: null
                }}
                onSubmit={(values, { setErrors }) => accountStore.register(values).catch(error => setErrors({ error }))}
                validationSchema={Yup.object({
                    userName: Yup.string().required(),
                    realName: Yup.string().required(),
                    email: Yup.string().required().email(),
                    password: Yup.string().required(),
                })}
            >

                {({ handleSubmit, handleChange, isSubmitting, errors }) => (
                    <>
                        <IonLoading isOpen={isSubmitting} spinner="crescent"  />
                        <Form onSubmit={handleSubmit} autoComplete="off" style={{ zIndex: 1 }}>
                            <TextField
                                style={{ margin: "16px 0" }}
                                name="userName"
                                placeholder="Skærmnavn"
                                label="Skærmnavn"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"> <Smartphone /> </InputAdornment>
                                }}
                                onChange={handleChange}
                            />
                            <TextField
                                style={{ margin: "16px 0" }}
                                name="realName"
                                placeholder="Dit navn"
                                label="Dit navn"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"> <Person /> </InputAdornment>
                                }}
                                onChange={handleChange}
                            />
                            <TextField
                                style={{ margin: "16px 0" }}
                                name="email"
                                placeholder="Email"
                                label="Email"
                                inputMode="email"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"> <Email /> </InputAdornment>
                                }}
                                onChange={handleChange}
                            />
                            <TextField
                                style={{ margin: "16px 0" }}
                                name="password"
                                placeholder="Kodeord"
                                label="Kodeord"
                                type="password"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"> <Lock /> </InputAdornment>
                                }}
                                onChange={handleChange}
                            />
                            <ErrorMessage
                                name="error" render={() => <p style={{ marginBottom: 16, color: "#FB2626" }}>{errors.error}</p>}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                size={"large"}
                                style={{ marginTop: 24 }}
                                sx={{
                                    bgcolor: deepPurple[500], color: deepPurple[50], transition: "225ms",
                                    ":hover": {
                                        bgcolor: deepPurple[800],
                                        color: "white"
                                    }
                                }}
                            >
                                Opret Konto
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>


            <div style={styles.shifterContainer}>
                <p style={styles.shifterLabel} >Er du allerede oprettet?</p>
                <Button
                    variant="outlined"
                    onClick={() => history.push(`/${Routes.LoginScreen}`)}
                    sx={{
                        borderColor: deepPurple[500], color: deepPurple[500], transition: "225ms",
                        ":hover": {
                            bgcolor: deepPurple[50]
                        }
                    }}
                >
                    Skift Til Login
                </Button>
            </div>
        </div>
    );
}

export default observer(RegisterForm);