import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { Check } from '@mui/icons-material';
import { Avatar, Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import { deepPurple, red } from '@mui/material/colors';
import { typographyVariant } from '@mui/system';
import { format } from 'date-fns';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FeedTrip } from '../../models/FeedTrip';
import { urls } from "../../shared/placeholders";

interface FeedListItemProps extends RouteComponentProps<{}> {
    feedItem: FeedTrip
}

const FeedListItem: React.FunctionComponent<FeedListItemProps> = ({ feedItem }) => {

    return (
        <Card sx={{ maxWidth: 540, width: "92vw", margin: "16px auto" }} >
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: deepPurple[500] }} aria-label="recipe">
                        {feedItem.user.name.substring(0, 1)}
                    </Avatar>
                }
                title={feedItem.user.name}
                subheader={`${feedItem.spotName} • ${format(new Date(feedItem.tripDate), "dd-MM-yy")}`}
            />
            <CardMedia
                component="img"
                height={200}
                image={urls.image}
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                    <p style={{ alignItems: "center", display: "flex", columnGap: 4 }}>
                        <span><Check /></span> <span>{feedItem.catchAmount} fangster</span>
                    </p>
                </Typography>
            </CardContent>
        </Card>
    );
}

export default observer(FeedListItem);