import { IonContent, IonPage } from '@ionic/react';
import React, { FunctionComponent } from 'react';
import FishAnimation from '../components/Animation/Fish';
import LoginForm from '../components/Auth/LoginForm';
import RegisterForm from '../components/Auth/RegisterForm';

interface RegisterProps { };

const Register: FunctionComponent<RegisterProps> = () => {

    const styles = {
        title: {
            fontSize: "48pt",
            fontWeight: 400,
            margin: 0,
            marginTop: 40
        } as React.CSSProperties,

        subTitle: {
            fontSize: 14,
            fontWeight: 400,
            marginTop: 0,
            marginBottom: 48,
        } as React.CSSProperties,
    }

    return (
        <IonPage>
            <IonContent scrollY={false} className="ion-padding ion-margin">
                    <h1 style={styles.title}>Opret Konto</h1>
                    <h2 style={styles.subTitle}>- og velkommen til <b>FISHY</b></h2>

                    <RegisterForm />

                    <FishAnimation />
            </IonContent>
        </IonPage>
    );
}

export default Register;