import * as React from 'react';
import { IonContent, IonLoading, IonSpinner } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import AcademyListItem from './AcademyListItem';
import { useStore } from '../../stores/store';
import { useEffect } from 'react';
import { observer } from "mobx-react-lite";

interface AcademyListProps extends RouteComponentProps<{}> {
}

const AcademyList: React.FunctionComponent<AcademyListProps> = ({history, location, match}) => {
    const { academyStore } = useStore();
    const { loadFishSpecies, selectFish, fishList, loading } = academyStore;

    useEffect(() => {
        selectFish(null)  
        loadFishSpecies();
    }, [loadFishSpecies])

    if (fishList === null || fishList === undefined || loading) {
        return (
            <IonContent>
                <IonLoading isOpen={true} spinner={"crescent"} />
            </IonContent>
        )
    }
    else {
        return (
            <IonContent>
                {
                    fishList.map(x => {
                        return (
                            <>
                                <AcademyListItem key={x.id + x.latinName} fish={x} history={history} location={location} match={match} />
                            </>
                        )
                    })
                }
            </IonContent>
        );
    }
}

export default observer(AcademyList);