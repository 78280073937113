import { makeAutoObservable, runInAction } from "mobx";
import Catch, { CatchDataHandler } from "../models/Catch";
import { store } from "./store";
import { v4 as uuid } from "uuid";
import { localStorageName } from "../shared/enums/localStorage";
import FishSelect from "../models/FishSelect";
import agent from "../api/agent";
import Trip, { TripSubmit } from "../models/Trip";
import { Routes } from "../shared/Routes";
import { history } from "../App";

export default class TripStore {
    tripToEdit?: Trip;
    catchesToEdit?: Catch[] | any;
    catches: CatchDataHandler[] = [];
    fishSelect: FishSelect[] = [];
    tripStart: Date | number = new Date();
    activeSpotId: string = "";
    latitude: string | number = 0;
    longitude: string | number = 0;
    activeTrip?: Trip;

    constructor() {
        makeAutoObservable(this);
    }

    get tripDraftIsSaved() {
        return !!localStorage.getItem(localStorageName.currentTripDraft);
    }

    getSavedTripDraft = () => {
        const draft = localStorage.getItem(localStorageName.currentTripDraft);
        if (draft) {
            const deserialized: CatchDataHandler[] = JSON.parse(draft!);
            runInAction(() => (this.catches = deserialized));
        }
    };

    getFishListForSelect = async () => {
        var fishList = await agent.Trips.listFish();
        runInAction(() => (this.fishSelect = fishList));
    };

    startNewTrip = async (spotId: string, lat: string, lon: string) => {
        runInAction(() => {
            this.catches = [];
            this.tripStart = Date.now();
            this.activeSpotId = spotId;
            this.latitude = lat;
            this.longitude = lon;
        });

        history.push(`/${Routes.NewTrip}`);
    };

    addNewCatch = async (incomingCatch: any) => {
        const fish = store.tripStore.fishSelect.find((x) => x.id == incomingCatch.fishId);

        const newCatch: any = {
            fishSpecies: {
                id: fish!.id,
                name: fish!.fishName,
            },
            lengthInCentimeters: +incomingCatch.length.replace(",", "."),
            weightInKilograms: +incomingCatch.weight.replace(",", "."),
        };

        runInAction(() => this.catches.push(newCatch));

        localStorage.setItem(localStorageName.currentTripDraft, JSON.stringify(this.catches));
    };

    submitTrip = async () => {
        const tripToPost: TripSubmit = {
            userId: store.accountStore.user!.id,
            spotId: this.activeSpotId,
            catches: this.catches,
            tripStart: new Date(this.tripStart),
            tripEnd: new Date(Date.now()),
        };

        await agent.Trips.createTrip(tripToPost);
    };

    cancelTrip = async () => {
        localStorage.removeItem(localStorageName.currentTripDraft);
        runInAction(() => {
            this.catches = [];
            this.activeSpotId = "";
            this.latitude = 0;
            this.longitude = 0;
        });

        history.push(`/${Routes.HomeScreen}`);
    };

    // getTrip = async (tripId: string) => {
    //     const _trip = await agent.Trips.getTrip(tripId);
    //     runInAction(() => (this.tripToEdit = _trip));
    //     runInAction(() => (this.tripToEdit = _trip));
    // };

    // getTripCatches = async (tripId: string) => {
    //     const _catches = await agent.Trips.getCatches(tripId);
    //     runInAction(() => this.catchesToEdit = _catches);
    // }
}
