import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { WindAndTemps } from "../models/Weather";
import YrResponse from "../models/YrWeather";
import { localStorageName } from "../shared/enums/localStorage";

interface savedCoords {
  lon: string | number;
  lat: string | number;
  expires: number | Date;
  lastModified: number | Date;
  weather: WindAndTemps;
}


export default class WeatherStore {
  loadingWeather: boolean = false;
  weatherAtCoordinates?: YrResponse;
  weatherAtMapCenter?: WindAndTemps;

  constructor() {
    makeAutoObservable(this);
  }

  getWeatherFromCoordinates = async (lat: number, lon: number) => {
    return;

    this.setLoading(true);

    try {
      const coordinatesWeather = await agent.Weather.get(
        +lat.toFixed(3),
        +lon.toFixed(3),
        null
      );

      runInAction(() => {
        this.weatherAtCoordinates = coordinatesWeather.data;
      });
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
  };

  getWeatherAtMapCenter = async (lat: number, lon: number) => {
    this.setLoading(true);

    let shouldCallForWeather: boolean = false;

    const storedCoordinatesExist = localStorage.getItem(
      localStorageName.mapCenterStoredCoordinates
    );

    if (storedCoordinatesExist == null) {
      shouldCallForWeather = true;
    } else {
      const storedCoordinates: savedCoords = JSON.parse(storedCoordinatesExist);

      if (storedCoordinates.expires < Date.now()) shouldCallForWeather = true;
      if (storedCoordinates.lat > lat + 0.25 || storedCoordinates.lat < lat - 0.25) shouldCallForWeather = true;
      if (storedCoordinates.lon > lon + 0.3 || storedCoordinates.lon < lon - 0.3) shouldCallForWeather = true;
    }

    if (shouldCallForWeather) {
      const savedCoords: savedCoords = JSON.parse(storedCoordinatesExist!);
      try {
        const coordinatesWeather = await agent.Weather.get(
          +lat.toFixed(3),
          +lon.toFixed(3),
        ).then((res) => {

          const newCenterCoordinates: savedCoords = {
            lon: lon,
            lat: lat,
            expires: Date.parse(res.headers.expires),
            lastModified: Date.parse(res.headers["last-modified"]),
            weather: {
              windSpeed:
                res.data.properties.timeseries[0].data.instant.details.wind_speed,
              airTemperature:
                res.data.properties.timeseries[0].data.instant.details.air_temperature,
            },
          };

          localStorage.setItem(
            localStorageName.mapCenterStoredCoordinates,
            JSON.stringify(newCenterCoordinates)
          );

          return res.data;
        
        });

        runInAction(() => {
          this.weatherAtMapCenter = {
            windSpeed:
              coordinatesWeather.properties.timeseries[0].data.instant.details
                .wind_speed,
            airTemperature:
              coordinatesWeather.properties.timeseries[0].data.instant.details
                .air_temperature,
          };
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      const storedWeather: savedCoords = JSON.parse(storedCoordinatesExist!);
      this.weatherAtMapCenter = storedWeather.weather;
    }

    this.setLoading(false);
  };

  private setLoading = (state: boolean) => {
    this.loadingWeather = state;
  };
}
