import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { createBrowserHistory } from "history";
import { book, map, people } from 'ionicons/icons';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AcademyFishDetails from './components/Academy/AcademyFishDetails';
import AcademyScreen from './pages/AcademyScreen';
import FeedScreen from './pages/FeedScreen';
import HomeScreen from './pages/HomeScreen';
import LoginScreen from './pages/LoginScreen';
import NewTripScreen from './pages/NewTripScreen';
import ProfileScreen from './pages/ProfileScreen';
import ProfileSettingsScreen from './pages/ProfileSettingsScreen';
import RegisterScreen from './pages/RegisterScreen';
import { Routes, subRoutes } from './shared/Routes';
import { useStore } from './stores/store';
/* Theme variables */
import './theme/variables.css';

export const history = createBrowserHistory();

const App: React.FC = () => {
    const { commonStore, accountStore } = useStore();

    useEffect(() => {
        if (commonStore.token) {
            accountStore.getUser().finally(() => commonStore.setApploaded());
        }
        else {
            commonStore.setApploaded();
        }
    }, [commonStore, accountStore])

    return (
        <IonApp>
            <ToastContainer position="bottom-center" hideProgressBar />

            <IonReactRouter history={history}>
                <IonTabs>
                    <IonRouterOutlet>

                        {/* App Screens */}
                        <Route exact path={`/:tab(${Routes.FeedScreen})`} render={(props) => <FeedScreen {...props} />} />
                        <Route exact path={`/:tab(${Routes.AcademyScreen})`} render={(props) => <AcademyScreen {...props} />} />
                        <Route path={`/:tab(${Routes.AcademyScreen})/${subRoutes.fishDetails}/:id`} render={(props) => <AcademyFishDetails {...props} />} />
                        <Route exact path={`/:tab(${Routes.HomeScreen})`} render={(props) => <HomeScreen {...props} />} />
                        <Route exact path={`/${Routes.LoginScreen}`} component={LoginScreen} />
                        <Route exact path={`/${Routes.RegisterScreen}`} component={RegisterScreen} />
                        <Route exact path={`/${Routes.ProfileSettings}`} component={ProfileSettingsScreen} />
                        <Route exact path={`/${Routes.Profile}/:id`} render={(props) => <ProfileScreen {...props} />} />
                        <Route exact path={`/${Routes.NewTrip}`} render={(props) => <NewTripScreen {...props} />} />

                        {/* TODO: Crate error screens */}
                        {/* Error Screens */}
                        <Route exact path={`/${Routes.NotFoundScreen}`} component={RegisterScreen} />
                        <Route exact path={`/${Routes.ServerErrorScreen}`} component={RegisterScreen} />

                        {/* Redirects */}
                        <Redirect exact from="/ny-bruger" to={`/${Routes.RegisterScreen}`} />
                        <Redirect exact from="/nybruger" to={`/${Routes.RegisterScreen}`} />
                        <Redirect exact from="/opret-bruger" to={`/${Routes.RegisterScreen}`} />
                        <Redirect exact from="/opretbruger" to={`/${Routes.RegisterScreen}`} />
                        <Redirect exact from="/indstillinger" to={`/${Routes.ProfileSettings}`} />
                        <Redirect exact from="/map" to={`/${Routes.HomeScreen}`} />
                        <Redirect exact from="/kort" to={`/${Routes.HomeScreen}`} />
                        <Redirect exact from="/" to={`/${Routes.HomeScreen}`} />

                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="home" href={`/${Routes.HomeScreen}`} >
                            <IonIcon icon={map} />
                            <IonLabel>Kort</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="academy" href={`/${Routes.AcademyScreen}`} >
                            <IonIcon icon={book} />
                            <IonLabel>Akademi</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="feed" href={`/${Routes.FeedScreen}`} >
                            <IonIcon icon={people} />
                            <IonLabel>Feed</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>

            </IonReactRouter>
        </IonApp>
    );
}

export default observer(App);
