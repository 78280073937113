import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";
import BestCatch from "../components/BestCatch/BestCatch";
import UnitCounterContainer from "../components/UnitCounter";
import { UnitCounterProps } from "../components/UnitCounter/UnitCounter";
import UserProfileStacked from "../components/User/UserProfileStacked";
import UserTripHistory from "../components/User/UserTripHistory";
import { history } from "../App";
import { RouteComponentProps } from "react-router";
import { useStore } from "../stores/store";


interface ProfileScreenProps extends RouteComponentProps<{ id: string }> {

}

const ProfileScreen: FunctionComponent<ProfileScreenProps> = ( {match} ) => {
    const { usersStore } = useStore();
    const {
        getUserProfile,
        getUserStats,
        getUserBestCatch,
        getUserTripHistory,
        stats,
        bestCatch,
        tripHistory
    } = usersStore;

    useEffect(() => {
        getUserProfile(match.params.id)
        getUserStats(match.params.id)
        getUserBestCatch(match.params.id)
        getUserTripHistory(match.params.id)
    }, [getUserProfile, getUserBestCatch, getUserTripHistory])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-margin">

                <UserProfileStacked
                    style={{ marginTop: 40 }}
                />

                <UnitCounterContainer items={stats} />

                <BestCatch userCatch={bestCatch} />

                <UserTripHistory items={tripHistory} />

            </IonContent>
        </IonPage>
    );
}

export default observer(ProfileScreen);