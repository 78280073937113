import { Login } from '@mui/icons-material';
import { Avatar, Button } from '@mui/material';
import { deepPurple, common } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';
import { useStore } from '../../stores/store';
import { history } from "../../App";
import { Routes } from '../../shared/Routes';

interface UserAvatarProps {
    imageUrl?: string;
    userName?: string;
}

const UserAvatar: FunctionComponent<UserAvatarProps> = ({ imageUrl, userName }) => {
    const { accountStore } = useStore();
    const { isLoggedIn, user } = accountStore;

    let userAvatarToDisplay = null;

    if (isLoggedIn) {
        if (imageUrl != null && imageUrl != undefined) {
            userAvatarToDisplay = (
                <Avatar src={imageUrl} onClick={() => history.push(`/${Routes.ProfileSettings}`)} />
            )
        }
        else {
            userAvatarToDisplay = (
                <Avatar sx={{ bgcolor: deepPurple[500] }} onClick={() => history.push(`/${Routes.ProfileSettings}`)}>
                    {userName?.substring(0, 1)}
                </Avatar>
            )
        }
    }
    else {
        userAvatarToDisplay = (
            <Button
                variant="outlined"
                sx={{ color: common.white, borderColor: common.white }}
                onClick={() => history.push(`/${Routes.LoginScreen}`)}
            >
                Login
            </Button>
        )
    }

    return userAvatarToDisplay;
}

export default observer(UserAvatar);