export enum WindMeasurementTypes {
  metersPerSecond = "m/s",
  kilometersPerHour = "km/t",
  knots = "Knots",
}

export enum TemperatureMeasurementTypes {
  celcius = "°C",
  fahrenheit = "°F",
}

export enum DistanceMeasuremntTypes {
  meters = "m",
  feet = "feet",
  centimeters = "cm",
}

export enum UnitTypes {
  wind = "vind",
  temperautre = "temp.",
  depth = "dybde",
  minLength = "min. længde",
}

export default {
  WindMeasurementTypes,
  TemperatureMeasurementTypes,
  DistanceMeasuremntTypes,
  UnitTypes,
};
