import { ButtonBase } from "@mui/material";
import { FunctionComponent } from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

interface SettingsMenuItemProps {
    icon?: React.ReactNode;
    itemText?: string;
    endItem?: React.ReactNode
    onClick?: () => {} | void;

    iconStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    endItemStyle?: React.CSSProperties;

    sX?: SxProps<Theme> | undefined
}

const SettingsMenuItem: FunctionComponent<SettingsMenuItemProps> = ({
    icon,
    itemText,
    endItem,
    iconStyle,
    textStyle,
    endItemStyle,
    sX,
    onClick
}) => {

    const styles = {
        container: {
            padding: "16px 24px",
            width: "100%",
            display: "grid",
            gridTemplateColumns: "max-content max-content 1fr",
            gap: 24,
            alignItems: "center"
        } as React.CSSProperties,

        text: {
            fontSize: 16,
            fontWeight: 600,
            letterSpacing: "0.02857em"
        } as React.CSSProperties,

        endItem: {
            fontSize: 14,
            fontWeight: 400,
            justifySelf: "end"
        } as React.CSSProperties,
    }

    return (
        <ButtonBase
            style={{ ...styles.container, }}
            sx={sX}
            onClick={onClick}
        >
            <div style={iconStyle}>
                {icon ?? icon}
            </div>
            <p style={{ ...styles.text, ...textStyle }}>
                {itemText ?? itemText}
            </p>
            <div style={{ ...styles.endItem, ...endItemStyle }}>
                {endItem ?? endItem}
            </div>
        </ButtonBase>
    );
}

export default SettingsMenuItem;