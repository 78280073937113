import { Image } from "@mui/icons-material";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { CatchDataHandler } from "../../models/Catch";
import { useStore } from "../../stores/store";

interface CatchListProps {
}

const CatchList: FunctionComponent<CatchListProps> = () => {
    const { tripStore } = useStore();
    const { catches } = tripStore;

    return (
        <List sx={{ width: '92vw', maxWidth: 540, margin: "8px auto" }}>

            {
                catches?.map(x => {
                    return (
                        <ListItem key={`${x.fishSpecies.id} ${x.lengthInCentimeters} ${x.weightInKilograms}`}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Image />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={x.fishSpecies.name}
                                secondary={`
                                ${x.lengthInCentimeters?.toString().replace(".", ",")} cm,
                                 ${x.weightInKilograms?.toString().replace(".", ",")} kg
                                 `}
                            />
                        </ListItem>
                    )
                })
            }
        </List>

    );
}

export default observer(CatchList);