import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { ChevronRight, Logout, Settings } from '@mui/icons-material/';
import { Button } from '@mui/material';
import { deepPurple, red } from '@mui/material/colors';
import { chevronBackOutline } from 'ionicons/icons';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';
import SettingsMenuItem from '../components/Settings/SettignsMenuItem';
import UserProfileInline from '../components/User/UserProfileInline';
import { useStore } from '../stores/store';
import { history } from "../App";
import { Routes } from "../shared/Routes";

interface ProfileSettingsScreenProps {

}

const ProfileSettingsScreen: FunctionComponent<ProfileSettingsScreenProps> = () => {
    const { accountStore } = useStore();
    const { logout, user } = accountStore;

    const styles = {
        menuItemsContainer: {
            marginTop: 32,
            display: "grid",
            gridAutoRows: "max-content",
            gap: 16,
        } as React.CSSProperties,
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" icon={chevronBackOutline} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-margin">

                <UserProfileInline
                    style={{ marginTop: 24, padding: "0 16px", cursor: "pointer" }}
                    onClick={() => history.push(`/${Routes.Profile}/${user?.id}`)}
                />

                <div style={styles.menuItemsContainer}>
                    {/* <SettingsMenuItem
                        icon={<Settings />}
                        itemText="Enheder"
                        endItem={<ChevronRight />}
                        onClick={() => { }}
                        sX={{
                            color: deepPurple[400], transition: "225ms",
                            ":hover": {
                                bgcolor: deepPurple[50],
                                color: deepPurple[600]
                            }
                        }}
                        textStyle={{ color: "rgba(0,0,0,0.87)" }}
                    /> */}

                    <SettingsMenuItem
                        icon={<Logout />}
                        itemText="Log ud"
                        endItem="Ses senere"
                        onClick={logout}
                        sX={{
                            bgcolor: red[50], color: red[400], transition: "225ms", alignSelf: "end",
                            ":hover": {
                                bgcolor: red[100],
                                color: red[600]
                            }
                        }}
                    />
                </div>

            </IonContent>
        </IonPage>
    );
}

export default observer(ProfileSettingsScreen);