import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { FishSpecies } from "../models/FishSpecies";

export default class AcademyStore {
  fishList: FishSpecies[] = [];
  selectedFish?: FishSpecies;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadFishSpecies = async () => {
    this.setLoading(true);

    try {
      const fish = await agent.Fish.listAcademy();

      let newArray: FishSpecies[] = [];

      fish.forEach((fishInList) => {
        newArray.push(fishInList);
      });

      runInAction(() => {
        this.fishList = newArray;
      });
    } catch (err) {
      console.log(err);
    }
    this.setLoading(false);
  };

  selectFish = async (id: string | null) => {
    this.setLoading(true);

    if (id === null) {
      runInAction(() => this.selectedFish === undefined);
      return;
    }
    const fishToBeSelected = this.fishList!.find((x) => x.id === id);

    if (fishToBeSelected == undefined) return;

    try {
      runInAction(async () => {
        this.selectedFish = await agent.Fish.details(fishToBeSelected.id);
      });
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
  };

  private setLoading = (state: boolean) => (this.loading = state);
}
