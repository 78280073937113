import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { history } from "../App";
import { Routes } from "../shared/Routes";
import { User, UserFormValues } from "../models/User";
import { store } from "./store";

export default class AccountStore {
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      runInAction(() => history.push(`/${Routes.HomeScreen}`));
      console.log(user);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
    history.push(`/${Routes.HomeScreen}`);
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      runInAction(() => (this.user = user));
    } catch (err) {
      throw err;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.register(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      history.push(`/${Routes.HomeScreen}`);
    } catch (err) {
      throw err;
    }
  };
}
