import { createContext, useContext } from "react";
import SpotStore from "./spotStore";
import ModalStore from "./modalStore";
import UiStore from "./uiStore";
import AccountStore from "./accountStore";
import WeatherStore from "./weatherStore";
import AcademyStore from "./academyStore";
import CommonStore from "./commonStore";
import UsersStore from "./usersStore";
import FeedStore from "./feedStore";
import TripStore from "./tripStore";

interface Store {
    spotStore: SpotStore;
    accountStore: AccountStore;
    modalStore: ModalStore;
    uiStore: UiStore;
    weatherStore: WeatherStore;
    academyStore: AcademyStore;
    commonStore: CommonStore;
    usersStore: UsersStore;
    feedStore: FeedStore;
    tripStore: TripStore;
}

export const store: Store = {
    spotStore: new SpotStore(),
    accountStore: new AccountStore(),
    modalStore: new ModalStore(),
    uiStore: new UiStore(),
    weatherStore: new WeatherStore(),
    academyStore: new AcademyStore(),
    commonStore: new CommonStore(),
    usersStore: new UsersStore(),
    feedStore: new FeedStore(),
    tripStore: new TripStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
