import { fontWeight } from "@mui/system";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { User, UserProfileDto } from "../../models/User";
import { useStore } from "../../stores/store";
import UserAvatar from "./UserAvatar";

interface UserProfileStackedProps {
    onClick?: () => {} | void;
    style?: React.CSSProperties;
}

const UserProfileStacked: FunctionComponent<UserProfileStackedProps> = ({ onClick, style }) => {
    const { accountStore, usersStore } = useStore();
    const { user } = accountStore;
    const { userProfile } = usersStore;

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
            width: "100%"
        } as React.CSSProperties,

        textContainer: {
            display: "flex",
            flexDirection: "column",
        } as React.CSSProperties,

        name: {
            fontSize: 20,
            fontWeight: 500,
            margin: 0,
            letterSpacing: "0.0125rem"
        } as React.CSSProperties,

        homeTown: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            transform: "translateY(-0.1250em)",
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "0.0500rem"
        } as React.CSSProperties,


    }

    return (
        <div style={{ ...styles.container, ...style }} onClick={onClick}>
            <UserAvatar imageUrl={userProfile?.image} userName={userProfile?.name} />
            <div style={styles.textContainer}>
                <h2 className="ion-text-center" style={styles.name}> {userProfile?.name} </h2>
                <h4 className="ion-text-center" style={styles.homeTown}> {userProfile?.homeTown} </h4>
            </div>
        </div>
    );
}

export default observer(UserProfileStacked);