import { IonPage } from '@ionic/react';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import AcademyList from '../components/Academy';

interface AcademyScreenProps extends RouteComponentProps<{}> {
}

const AcademyScreen: FunctionComponent<AcademyScreenProps> = (props) => {
    return (
        <IonPage>
            <AcademyList {...props} />
        </IonPage>
    );
}

export default AcademyScreen;